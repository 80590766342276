.facts-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:40px;

}
.questions-container{

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.questions-blurb{

    width:80%;
}
.prompt-container{
    margin:10px;

    width: 60%;
}
.prompt{
    font-size: 20pt;
    font-family:  "Jetbrains Mono", sans-serif;
    width: 70%;
}
.ask-button{
    font-size: 24pt;
    font-family: "Jetbrains Mono", sans-serif;
    color:white;
    background-color: black;
    border-style: hidden;
}
.response-container{
    width: 80%;
    position:absolute;
    bottom:-50px;;
    height:150px;
    width:100%;
    left:0;
    animation-name:slideup;
    animation-delay:0s;
    animation-duration:0.8s;
    animation-fill-mode:forwards;
    animation-timing-function:ease-out;  
}
@keyframes slideup {
    0%{bottom:-200px}
    100%{bottom:0;}
}
  
.example-questions{
    text-align: left;
    width: 100%;
    padding-left: 65%;    
}
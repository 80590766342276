.engs {
    display: flex;
    flex-direction: column;
    // border-style: dotted;

}

h1 {
    text-align: center;
}

.blurb {
    margin: 40px;

}

.page {
    display: flex;
    flex-direction: column;
}

.engs-text {
    //border-style: dotted;
    padding: 10px;
}

.engs-section {
    display: flex;
    flex-direction: row;
    //justify-content: space-around;
    margin: 20px;
    //border-style: dotted;

}

.engs-pic {
    //border-style: dotted;
    margin: 10px;
    display: none;
}

.val-pic {
    width: 40%;
    margin: 10px;
}

img {
    object-fit: contain;
}
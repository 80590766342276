body, html {
    font-family: "Jetbrains Mono", sans-serif;
    height: 100%;
    margin:0px;
  }

@media only screen and (min-width: 701px) {
    #mobile{
      display: none;
  }
}

@media only screen and (max-width: 700px) {
  #main {
    *{
      display: none;
    }
    #mobile{
      display: inline;
    }
  }
}

#main{
  margin-top: 100px
}

.navbar {
  background-color: black;

  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  padding: 12px;
  height: 30px;
  font-size: 16pt;
  // border-style: dashed;
  // border-color: rgb(163, 149, 149);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  z-index: 10000;
  a{
    color: white;
    padding: 3px 10px 3px 10px;
    text-decoration: none;
    &.active {
      color:black;
      background: white;
  }
}
  a:hover{
    color:grey;
  }
}

.privacy{
  height: 40px;
  color:white;
  background-color: black;
  text-align: center;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;

  a {
    text-decoration: none;
  }
}